import { createRouter, createWebHistory } from '@ionic/vue-router';

const routes = [
  // V1
  {
    path: '/user-agreement',
    component: () => import('@/views/v1/UserAgreement.vue')
  },
  {
    path: '/category/:slug',
    props:true,
    component: () => import('@/views/v1/Category.vue')
  },
  {
    path: '/page/:slug',
    props:true,
    component: () => import('@/views/v1/PageProfile.vue')
  },
  {
    path: '/genre/:slug',
    props:true,
    component: () => import('@/views/v1/Genre.vue')
  },
  {
    path: '/genres',
    component: () => import('@/views/v1/Genres.vue')
  },
  {
    path: '/admin-post/:id',
    props:true,
    component: () => import('@/views/v1/AdminPostEdit.vue')
  },
  {
    path: '/admin-page/:id',
    props:true,
    component: () => import('@/views/v1/AdminPageEdit.vue')
  },
  {
    path: '/admin-category/:id',
    props:true,
    component: () => import('@/views/v1/CategoryEdit.vue')
  },
  {
    path: '/admin-categories',
    component: () => import('@/views/v1/AdminCategories.vue')
  },
  {
    path: '/scene/:slug',
    props:true,
    component: () => import('@/views/v1/SceneSingle.vue')
  },
  {
    path: '/admin-genre/:id',
    props:true,
    component: () => import('@/views/v1/AdminGenreEdit.vue')
  },
  {
    path: '/admin-genres',
    component: () => import('@/views/v1/AdminGenres.vue')
  },
  {
    path: '/admin-scene/:id',
    props:true,
    component: () => import('@/views/v1/AdminSceneEdit.vue')
  },
  {
    path: '/admin-scenes',
    component: () => import('@/views/v1/AdminScenes.vue')
  },
  {
    path: '/admin-pages',
    component: () => import('@/views/v1/AdminPages.vue')
  },
  {
    path: '/admin-posts',
    component: () => import('@/views/v1/AdminPosts.vue')
  },
  {
    path: '/admin',
    component: () => import('@/views/v1/Admin.vue')
  },
  {
    path: '/profile-search',
    component: () => import('@/views/v1/ProfileSearch.vue')
  },
  {
    path: '/post-search',
    component: () => import('@/views/v1/PostSearch.vue')
  },
  {
    path: '/post-options',
    component: () => import('@/views/v1/PostOptions.vue')
  },
  {
    path: '/profile-sort',
    component: () => import('@/views/v1/ProfileSort.vue')
  },
  {
    path: '/',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/on-boarding',
    name: 'OnBoarding',
    component: () => import('@/views/OnBoarding.vue')
  },
  {
    path: '/home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/sign-in',
    name: 'SignInPage',
    component: () => import('@/views/SignInPage.vue')
  },
  {
    path: '/sign-up',
    name: 'SignUpPage',
    component: () => import('@/views/SignUpPage.vue')
  },
  {
    path: '/explore',
    name: 'Explore', 
    component: () => import('@/views/Explore.vue')
  },
  {
    path: '/profile-view',
    name: 'ProfileView', 
    component: () => import('@/views/ProfileView.vue')
  },
  {
    path: '/update-profile-photo',
    name: 'ProfilePhotoAdmin', 
    component: () => import('@/views/v1/ProfilePhotoAdmin.vue')
  },
  {
    path: '/profile-edit',
    name: 'ProfileEdit', 
    component: () => import('@/views/ProfileEdit.vue')
  },
  {
    path: '/profile-settings',
    name: 'ProfileSettings', 
    component: () => import('@/views/ProfileSettings.vue')
  },
  {
    path: '/profile-short-description',
    name: 'ProfileShortDescription', 
    component: () => import('@/views/v1/ProfileShortDescription.vue')
  },
  {
    path: '/profile-name',
    name: 'ProfileNameAdmin', 
    component: () => import('@/views/v1/ProfileNameAdmin.vue')
  },
  {
    path: '/profile-username',
    name: 'ProfileUserNameAdmin', 
    component: () => import('@/views/v1/ProfileUserNameAdmin.vue')
  },
  {
    path: '/profile-email',
    name: 'ProfileEmailAdmin', 
    component: () => import('@/views/v1/ProfileEmailAdmin.vue')
  },
  {
    path: '/profile-phone',
    name: 'ProfilePhoneAdmin', 
    component: () => import('@/views/v1/ProfilePhoneAdmin.vue')
  },
  {
    path: '/profile-location',
    name: 'ProfileLocationAdmin', 
    component: () => import('@/views/v1/ProfileLocationAdmin.vue')
  },
  {
    path: '/profile-genres',
    name: 'ProfileGenresAdmin', 
    component: () => import('@/views/v1/ProfileGenresAdmin.vue')
  },
  {
    path: '/profile-notification-settings',
    name: 'ProfileNotificationsAdmin', 
    component: () => import('@/views/v1/ProfileNotificationsAdmin.vue')
  },
  {
    path: '/profile-security',
    name: 'ProfileSecurityAdmin', 
    component: () => import('@/views/v1/ProfileSecurityAdmin.vue')
  },
  {
    path: '/profile-status',
    name: 'ProfileStatusAdmin', 
    component: () => import('@/views/v1/ProfileStatusAdmin.vue')
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('@/views/Notifications.vue')
  },
  {
    path: '/messages',
    name: 'Messages',
    component: () => import('@/views/v1/Messages.vue')
  },
  {
    path: '/message/:id',
    name: 'MessageDetail', 
    props:true,
    component: () => import('@/views/v1/MessageDetail.vue')
  },
  {
    path: '/create-message',
    name: 'CreateMessage',
    component: () => import('@/views/v1/CreateMessage.vue')
  },
  {
    path: '/post/:slug',
    name: 'PostSingle', 
    props:true,
    component: () => import('@/views/PostSingle.vue')
  },
  {
    path: '/edit-post/:slug',
    name: 'PostEdit', 
    props:true,
    component: () => import('@/views/v1/PostEdit.vue')
  },
  {
    path: '/profile/:slug',
    name: 'Profile',
    props: true,
    component: () => import('@/views/Profile.vue')
  },
  {
    path: '/place/:slug',
    name: 'Place',
    props:true,
    component: () => import('@/views/Place.vue')
  },
  {
    path: '/hashtag/:hashtag',
    name: 'HashtagFeed',
    props:true,
    component: () => import('@/views/Hashtag.vue')
  },
  {
    path: '/profile-connections',
    name: 'ProfileConnections',
    component: () => import('@/views/ProfileConnections.vue')
  },
  {
    path: '/followers/:username',
    name: 'Followers',
    props: true,
    component: () => import('@/views/v1/Followers.vue')
  },
  {
    path: '/following/:username',
    name: 'Following',
    props:true,
    component: () => import('@/views/v1/Following.vue')
  },
  {
    path: '/send-message/:username',
    name: 'SendMessageUser',
    props:true,
    component: () => import('@/views/v1/SendMessageUser.vue')
  },
  {
    path: '/scenes',
    name: 'Scenes',
    component: () => import('@/views/Scenes.vue')
  },
  {
    path: '/people',
    name: 'People',
    component: () => import('@/views/People.vue')
  },
  {
    path: '/artists',
    name: 'Artists',
    component: () => import('@/views/Artists.vue')
  },
  {
    path: '/venues',
    name: 'Venues',
    component: () => import('@/views/Venues.vue')
  },
  {
    path: '/pages',
    name: 'Pages',
    component: () => import('@/views/Pages.vue')
  },
  {
    path: '/feed',
    name: 'Feed',
    component: () => import('@/views/Feed.vue')
  },
  {
    path: '/concerts',
    name: 'Concerts',
    component: () => import('@/views/Concerts.vue')
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import('@/views/Events.vue')
  },
  {
    path: '/classifieds',
    name: 'Classifieds',
    component: () => import('@/views/Classifieds.vue')
  },
  {
    path: '/create-post',
    name: 'CreatePost',
    component: () => import('@/views/v1/CreatePost.vue')
  },
  {
    path: '/create-concert',
    name: 'CreateConcert',
    component: () => import('@/views/v1/CreateConcert.vue')
  },
  {
    path: '/add-venue',
    name: 'AddVenue',
    component: () => import('@/views/v1/AddVenue.vue')
  },
  {
    path: '/setup-tickets',
    name: 'SetupTickets',
    component: () => import('@/views/v1/SetupTickets.vue')
  },
  {
    path: '/add-genres',
    name: 'AddGenres',
    component: () => import('@/views/v1/AddGenres.vue')
  },
  {
    path: '/booking-details',
    name: 'BookingDetails',
    component: () => import('@/views/v1/BookingDetails.vue')
  },
  {
    path: '/create-classified',
    name: 'CreateClassified',
    component: () => import('@/views/v1/CreateClassified.vue')
  },
  {
    path: '/classified-categories',
    name: 'ClassifiedCategories',
    component: () => import('@/views/v1/ClassifiedCategories.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router