<template>
	<ion-app>
		<ion-router-outlet />
	</ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'App',
	components: {
		IonApp, IonRouterOutlet
	}
});
</script>
<!-- Global Style -->
<style>
#web-nav {
	display:none;
}
#mobile-web-nav {
	display:block;
}
#mobile-segment {
	display:block;
}
.max-width {
	width:100%; 
	max-width:1440px;
	margin:0 auto;
}
@media only screen and (min-width: 768px) {
	#web-nav {
		display:block;
	}
	#mobile-web-nav {
		display:none;
	}
	#mobile-segment {
		display:none;
	}
}
.bottom-padding {
	padding-bottom:79px!important;
}
.text-center {
	text-align: center!important;
}
.text-right {
	text-align: right!important;
}
.text-lg {
	font-size:18px;
}
.text-sm {
	font-size:12px;
}
.text-md {
	font-size:14px;
}
.bold {
	font-weight: bold;
}
.padding-top-md {
	padding-top:15px;
}
.padding-bottom-md {
	padding-bottom:15px;
}
.padding-left-md {
	padding-left:15px;
}
.padding-left-0 {
	padding-left:0px!important;
}
.padding-right-md {
	padding-right:15px;
}
.padding-md {
	padding:15px;
}
.padding-top-sm {
	padding-top:5px;
}
.padding-bottom-sm {
	padding-bottom:5px!important;
}
.padding-bottom-lg {
	padding-bottom:30px;
}
.p-0 {
	padding:0;
}
.pl-1 {
	padding-left: 1px;
}
.pr-1 {
	padding-right: 1px;
}
.padding-sm {
	padding:5px;
}
.margin-md {
	margin:15px;
}
.m-0 {
	margin:0;
}
.margin-left-md {
	margin-left:15px;
}
.margin-right-md {
	margin-right:15px;
}
.margin-left-sm {
	margin-left:5px;
}
.margin-right-sm {
	margin-right:5px;
}
.margin-bottom-sm {
	margin-bottom:5px;
}
.margin-bottom-md {
	margin-bottom:15px;
}
.border-bottom {
	border-bottom:1px solid #eeeeee;
}
.bg-light {
	color:#222428!important;
	background:#f4f5f8!important;
}
.bg-white {
	color:#222428!important;
	background:#ffffff!important;
}
.bg-dark {
	color:#f4f5f8!important;
	background:#222428!important;
}
.text-light {
	color:#f4f5f8!important;
}
.text-medium {
	color:#92949c!important;
}
.text-dark {
	color:#222428!important;
}
.text-danger {
	color:#eb445a!important;
}
.img-avatar {
	border-radius: 50%;
}
.w-100 {
	width:100%;
}
.avatar-md {
	max-width: 80px;
}
.margin-auto {
	margin:auto!important;
}
.text-capitalize {
	text-transform: capitalize;
}
.text-uppercase {
	text-transform: uppercase;
}
.pointer {
	cursor: pointer;
}

/*#dark-header ion-toolbar {
	width:100%; 
	max-width:760px;
	margin:0 auto;
	--background:#222428!important;
	--color:#f4f5f8!important;
}
#dark-header ion-icon {
	font-size:20px;
	color:#f4f5f8!important;
}*/
ion-icon  {
	font-size:16px;
}

ion-button {
	--box-shadow:none;
}

</style>
