import { createStore } from 'vuex'

const state = {
  sceneId: 0,
  appId: 1,
  isAuth: false,
  authToken: null,
  authUser: [],
  activeUser: false,
  displayForm: 'signin',
  uploadFile: [],
  base64: '',
  feedTrigger: false,
  genericTrigger: false,
  //Concert
  venue: [],
  tickets: [],
  storedGenres: [],
  bookingDetails: null,
  // Classifieds
  category: 'gigs',
  subCategory: null,
  // Register
  name: null,
  username: null,
  email: null,
  password: null,
  // Single Post
  commentId: 0,
  commentReply: [],
  commentToEdit: null,
  reloadSinglePost: false,
  // Toast
  isToast: false,
  toastMessage: null,
  // Modals
  dismissModal: false,
  dismissPostLikeModal: false,
  dismissCommentLikeModal: false,
  dismissCommentReplyLikeModal: false,
  // Post Likes
  storedPostLikes: [],
}

const mutations = {
  setSceneId(state, id) {
    state.sceneId = id
  },
  setIsAuth(state, val) {
    state.isAuth = val
  },
  setAuthToken(state, authToken) {
    state.authToken = authToken
  },
  unsetAuthToken(state) {
    state.authToken = null
  },
  setAuthUser(state, authUser) {
    state.authUser = authUser
  },
  unsetAuthUser(state) {
    state.authUser = []
  },
  setActiveUser(state, val) {
    state.activeUser = val
  },
  unsetActiveUser(state) {
    state.activeUser = false
  },
  setDisplayForm(state, val) {
    state.displayForm = val
  },
  setUploadFile(state, file) {
    state.uploadFile = file
  },
  setBase64(state, file) {
    state.base64 = file
  },
  setFeedTrigger(state, val) {
    state.feedTrigger = val
  },
  setGenericTrigger(state, val) {
    state.genericTrigger = val
  },
  ////// Concert
  setVenue(state, venue) {
    state.venue = venue
  },
  setTickets(state, ticket) {
    state.tickets.push(ticket)
  },
  removeTicket(state, i) {
    state.tickets.splice(i,1)
  },
  setStoredGenre(state, genre) {
    state.storedGenres.push(genre)
  },
  removeStoredGenre(state, i) {
    state.storedGenres.splice(i,1)
  },
  setBooking(state, details) {
    state.bookingDetails = details
  },
  setCategory(state, category) {
    state.category = category
  },
  setSubCategory(state, subCategory) {
    state.subCategory = subCategory
  },
  ////// Register
  setName(state, val) {
    state.name = val
  },
  setUsername(state, val) {
    state.username = val
  },
  setEmail(state, val) {
    state.email = val
  },
  setPassword(state, val) {
    state.password = val
  },
  setCommentId(state, val) {
    state.commentId = val
  },
  setCommentReply(state, val) {
    state.commentReply = val
  },
  setReloadSinglePost(state, val) {
    state.reloadSinglePost = val
  },
  // Toast
  setToast(state, val) {
    state.isToast = val
  },
  setToastMessage(state, val) {
    state.toastMessage = val
  },
  setDismissModal(state, val) {
    state.dismissModal = val
  },
  setDismissPostLikeModal(state, val) {
    state.dismissPostLikeModal = val
  },
  setDismissCommentLikeModal(state, val) {
    state.dismissCommentLikeModal = val
  },
  setDismissCommentReplyLikeModal(state, val) {
    state.dismissCommentReplyLikeModal = val
  },
  ////// Post Likes
  setPostLikes(state, likes) {
    state.storedPostLikes = likes
  },
  setPostLike(state, like) {
    state.storedPostLikes.push(like)
  },
  removePostLike(state, i) {
    state.storedPostLikes.splice(i,1)
  },
  setCommentToEdit(state, comment) {
    state.commentToEdit = comment
  }

}

export default createStore({
  state,
  mutations
})